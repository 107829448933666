#root {
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}

.mapboxgl-map {
  top: 0;
  bottom: 0;
  width: 100%;
}

#map {
  position:absolute; top:0; bottom:0; width:100%;
}


.basemapSelect {
  position: absolute !important;
  width : 140px;
  right: 8px;
  bottom:20px  !important;
  background-color:white;
  z-index: 2;
  box-Shadow:0 0 10px 2px rgba(0,0,0,.1);
}

.EditorButtonGroup {
  left: 55px;
    position: absolute;
    top: 10px;
    background-color: white;
}

.MuiExpansionPanel-root.isoPanel {
  width: 140px;
}
.isoPanel .MuiExpansionPanelSummary-root {
  padding: 0px 8px 0px 8px;
}
.MuiExpansionPanel-root.Mui-expanded.isoPanel {
  width: 300px;
  margin: 0px;
}
.isoPanel {
   right:8px;
   position:absolute !important;
   bottom:60px;
   font-size:12.25px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }

.carCountPanel {
  left:100px;
  width:150px;
  position:absolute;
  bottom:10px;
  font-size:12.25px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.listItem {
  cursor: pointer;
  text-align: left;
  vertical-align: middle;
  padding-top: 0.25em;

}

.selectedItem {
  background-color: #2ECC71;
}

.suggestions-wrapper {
  background-color: white;
}

.mapboxgl-ctrl-geocoder--pin-right {
  display: none;
}

.mapboxgl-ctrl-geocoder--input {
  font: inherit;
  font-size: 15px;
 padding: 5px 5px 5px 35px !important;
  border: 0;
  background-color: transparent;
  margin: 0;
  height: 35px;
  color: #404040;
  color: rgba(0, 0, 0, 0.75);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mapboxgl-ctrl-geocoder {
width: 100% !important;
max-width: 500px !important;
}

.geocoderCtrl {
  position:absolute;
  z-index:1;
  right:10px;
  box-shadow: 0 0 10px 2px rgba(0,0,0,.1);
  top:10px;
  height:30px;
  width:25%;
  
  }

.streetView {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 105px;
  right: 11px;
}

.MuiExpansionPanelSummary-root {
  min-height: 32px !important;
}

.MuiExpansionPanelSummary-root{
  border-bottom-right-radius:4px;
  border-bottom-top-radius:4px;
  border-bottom-left-radius:4px;
  border-bottom-bottom-radius:4px;
}

.MuiExpansionPanelSummary-content {
  margin: 6px 0 !important;
}

.MuiExpansionPanel-rounded {
  width: 90%;
  border-radius: 4px !important;
}

.MuiExpansionPanelDetails-root {
  padding: 8px 16px 16px !important;
}

.mapboxgl-canvas {
  width: 100% !important;
}

.kbd {
  display: inline-block;
  padding: 3px 5px;
  font: 11px SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace;
  line-height: 10px;
  color: #444d56;
  vertical-align: middle;
  background-color: #fafbfc;
  border: 1px solid #d1d5da;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 #d1d5da;
}

.mapbox-gl-draw_polygon.active {
  background-color: #2ECC71;
}

.mapbox-gl-draw_point.active {
  background-color: #2ECC71;
}

.mapboxgl-ctrl button:not(:disabled):hover{
  background-color: #2ECC71 !important;
}

/**
 Fix jumping outline for outlined textinput with multi-word legend
 NOTE: Should be added somewhere here
   https://github.com/mui-org/material-ui/blob/661adf7/packages/material-ui/src/OutlinedInput/NotchedOutline.js#L42
 */
fieldset.MuiOutlinedInput-notchedOutline > legend {
  white-space:nowrap;
}
